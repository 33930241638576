<script>
import {inject} from "vue";
import ConnectionStatus from "@/components/shared/Online.vue";

export default {
  components: {ConnectionStatus},

  setup() {
    const user = JSON.parse(localStorage.getItem('user'));
    const version = process.env.VUE_APP_VERSION;
    const envir = process.env.NODE_ENV;
    const appName = inject('appName');
    return { user, version, envir, appName }
  },
  methods: {
    async logout() {
      await localStorage.removeItem('user');
      await this.$router.push('login');
    },
  }
}
</script>

<template>
  <div class="w-full grid grid-cols-2 h-full hover:bg-green-600 bg-green-700 px-6 p-3">
    <div class="justify-start grid">
      <p class="font-bold text-white">{{appName}}</p>
      <p class="font-bold text-white">Ver.: {{version}} {{envir}}
        <connection-status></connection-status>
      </p>
    </div>
    <div class="justify-end grid">
      <p class="font-bold text-white">{{$t('ods_list.connected')}} :</p>
      <p class="text-white">{{user[0]?.email}}</p>
      <p class="text-white mt-4">{{$t('ods_list.not_you')}} <span class="font-bold cursor-pointer" @click.prevent="logout()">{{$t('ods_list.disconnect')}}</span></p>
    </div>
  </div>
</template>
